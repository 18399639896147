import React from "react";
import { graphql } from "gatsby";

import Layout from "../components/layout";
import SEO from "../components/seo";
import MainSectionZakaznickaZona from "../components/zakaznicka-zona/mainSection.js";

import "../styles/zakaznicka-zona.scss";
import ContactUs from "../components/contactUs";
import ZonaSolutionBox from "../components/zakaznicka-zona/zonaSolutionBox";
import ZonaFaqQuestion from "../components/zakaznicka-zona/zonaFaqQuestion.js";
import SliderSectionZona from "../components/zakaznicka-zona/sliderSection.js";
import References from "../components/homepage/references.js";
import ZonaClankySlider from "../components/zakaznicka-zona/zonaClankySlider";
import { GatsbyImage, StaticImage, getImage } from "gatsby-plugin-image";

import uvodnaKonzultaciaImg from "../images/zakaznicka-zona/uvodna-konzultacia.jpg";
import navrhRieseniaImg from "../images/zakaznicka-zona/navrh-riesenia.jpg";
import programovanieZonyImg from "../images/zakaznicka-zona/programovanie-zony.jpg";
import podporaImg from "../images/zakaznicka-zona/podpora.jpg";

import zadavanieIcon from "../images/zakaznicka-zona/icons/zadavanie-obj.svg";
import generovanieIcon from "../images/zakaznicka-zona/icons/generovanie-dokladov.svg";
import prezeranieIcon from "../images/zakaznicka-zona/icons/prezeranie-stahovanie-dokumentov.svg";
import spristupnenieIcon from "../images/zakaznicka-zona/icons/spristupnenie-udajov.svg";
import reportingIcon from "../images/zakaznicka-zona/icons/reporting.svg";

import usporaIcon from "../images/zakaznicka-zona/icons/uspora-casu.svg";
import znizenieIcon from "../images/zakaznicka-zona/icons/znizenie-nakladov.svg";
import lojalitaIcon from "../images/zakaznicka-zona/icons/vyssia-lojalita-zakaznikov.svg";
import fungovanieIcon from "../images/zakaznicka-zona/icons/nepretrzite-fungovanie.svg";
import rizikoIcon from "../images/zakaznicka-zona/icons/nizsie-riziko-chyb.svg";

import zonesColage from "../images/zakaznicka-zona/okna.jpg";

export const pageQuery = graphql`
  query {
    articles: wpCategory(slug: { eq: "zakaznicka-zona" }) {
      posts {
        nodes {
          title
          excerpt
          slug
          featuredImage {
            node {
              altText
              title
              gatsbyImage(width: 1920)
              sourceUrl
            }
          }
        }
      }
    }
    mysqlProjects(url: { eq: "plusim" }) {
      url
      real_url
      client
      hashtag
      project_description
      case_study_title
      case_study_rok
      case_study_sluzby
      case_study_vyzva
      case_study_proces
      case_study_riesenie
      ogDescription
    }
    files: allFile(
      filter: {
        relativeDirectory: { eq: "plusim" }
        sourceInstanceName: { eq: "images-projects" }
      }
    ) {
      nodes {
        name
        publicURL
        childImageSharp {
          gatsbyImageData
        }
      }
    }
    projekty: allMysqlProjects(sort: { fields: case_study_rok, order: DESC }) {
      nodes {
        url
        client
        case_study_description
      }
    }
    logosWhite: allFile(
      filter: {
        name: { eq: "logo-white" }
        sourceInstanceName: { eq: "images-projects" }
      }
    ) {
      nodes {
        name
        relativeDirectory
        publicURL
      }
    }
  }
`;

const ZakaznickaZona = (pageQuery) => {
  const images = {};
  pageQuery.data.files.nodes.map((node) => {
    let index = node.name;
    images[index] = node;
  });

  return (
    <Layout pageName="zakaznicka-zona-main">
      <SEO
        title="Zákaznícka zóna - Vytvoríme Vám ju"
        description="Vytvárame zákaznícke zóny na mieru, ktoré vám ušetria čas i náklady, sprehľadnia procesy a pomôžu zvýšiť lojalitu zákazníkov."
      />
      <MainSectionZakaznickaZona
        heading="Zákaznícka zóna pre Vašu spoločnosť"
        id="md"
        textHtml={
          <b>
            Vytvárame zákaznícke zóny, ktoré zvyšujú zisky, zjednodušujú
            administráciu,
            <br />
            redukujú náklady a zvyšujú lojalitu zákazníkov.
            <br />
            Pomôžeme Vám ju vyhotoviť.
          </b>
        }
      />
      <section id="what-is-client-zone">
        <ZonaSolutionBox
          heading="Čo je to zákaznícka zóna?"
          text="Zákaznícka zóna, inak nazývaná aj ako klientsky portál, B2B portál alebo zákaznícky portál, je prostredie, ktoré je určené pre vašich klientov. Môže byť súčasťou webstránky alebo v podobe samostatnej aplikácie. Zákazníci sa do neho štandardne dostanú prostredníctvom svojich prihlasovacích údajov."
        />
      </section>

      <section id="client-zone-colage">
        <img
          className="img"
          src={zonesColage}
          alt="Zákaznícke zóny"
          title="Zákaznícke zóny"
          loading="lazy"
        />
      </section>

      <section className="section-blog">
        <div className="banner-blog">
          <h2>Klientsky portál a jeho možnosti </h2>
          <p>
            Do zákazníckej zóny na mieru si môžete nechať naprogramovať rôzne
            funkcie. Je len na vás, ktoré chcete využívať. Tieto patria medzi
            najžiadanejšie.
          </p>
        </div>
      </section>
      <section id="case-study-info">
        <div class="vyzva">
          <div>
            <div class="iconContainer">
              <img
                className="img"
                src={zadavanieIcon}
                alt="Zadávanie nových objednávok"
                title="Zadávanie nových objednávok"
                loading="lazy"
              />
            </div>
          </div>
          <div>
            <h3>
              Zadávanie nových objednávok{" "}
              <StaticImage
                className="img"
                src="../images/new-web/arrow.svg"
                alt="Šípka vpravo"
                placeholder="blurred"
              />
            </h3>
            <p>
              Prostredníctvom zákazníckej zóny môžu klienti rýchlo zadávať nové
              objednávky bez toho, aby museli nanovo uvádzať svoje osobné alebo
              firemné údaje. Praktickou funkciou je kopírovanie starších
              objednávok, ale aj reklamovanie či vracanie tovaru.{" "}
            </p>
          </div>
        </div>
        <div class="vyzva">
          <div>
            <div class="iconContainer">
              <img
                className="img"
                src={generovanieIcon}
                alt="Generovanie dokladov"
                title="Generovanie dokladov"
                loading="lazy"
              />
            </div>
          </div>
          <div>
            <h3>
              Generovanie dokladov{" "}
              <StaticImage
                className="img"
                src="../images/new-web/arrow.svg"
                alt="Šípka vpravo"
                placeholder="blurred"
              />
            </h3>
            <p>
              Keď klient zadá objednávku cez zákaznícku zónu, automaticky sa mu
              môže vygenerovať faktúra alebo iný doklad.{" "}
            </p>
          </div>
        </div>
        <div class="vyzva">
          <div>
            <div class="iconContainer">
              <img
                className="img"
                src={prezeranieIcon}
                alt="Prezeranie a sťahovanie dokumentov"
                title="Prezeranie a sťahovanie dokumentov"
                loading="lazy"
              />
            </div>
          </div>
          <div>
            <h3>
              Prezeranie a sťahovanie dokumentov{" "}
              <StaticImage
                className="img"
                src="../images/new-web/arrow.svg"
                alt="Šípka vpravo"
                placeholder="blurred"
              />
            </h3>
            <p>
              Zákazníci môžu mať vo svojom profile uložené predchádzajúce
              objednávky vrátane všetkých dokladov. Kedykoľvek sa k nim vedia
              vrátiť a dokážu si ich stiahnuť.{" "}
            </p>
          </div>
        </div>
        <div class="vyzva">
          <div>
            <div class="iconContainer">
              <img
                className="img"
                src={spristupnenieIcon}
                alt="Sprístupnenie údajov"
                title="Sprístupnenie údajov"
                loading="lazy"
              />
            </div>
          </div>
          <div>
            <h3>
              Sprístupnenie údajov{" "}
              <StaticImage
                className="img"
                src="../../images/new-web/arrow.svg"
                alt="Šípka vpravo"
                placeholder="blurred"
              />
            </h3>
            <p>
              Cez klientsky portál viete svojim zákazníkom sprístupniť údaje
              podľa vášho výberu – stav produktov na sklade, dobu dodania,
              aktuálnu cenu a mnohé ďalšie dáta.{" "}
            </p>
          </div>
        </div>

        <div class="vyzva">
          <div>
            <div class="iconContainer">
              <img
                className="img"
                src={reportingIcon}
                alt="Reporting"
                title="Reporting"
                loading="lazy"
              />
            </div>
          </div>
          <div>
            <h3>
              Reporting{" "}
              <StaticImage
                className="img"
                src="../../images/new-web/arrow.svg"
                alt="Šípka vpravo"
                placeholder="blurred"
              />
            </h3>
            <p>
              Zákaznícka zóna vám vie v pravidelných intervaloch generovať
              reporty s údajmi podľa vašich potrieb a môže vám ich automaticky
              zasielať na váš e-mail.{" "}
            </p>
          </div>
        </div>
      </section>

      <section id="case-study-process" className="case-study-process-mac">
        <div className="comparison2">
          <GatsbyImage
            className="mockup-notebook notebook-image"
            image={getImage(images["web-new"])}
            alt="Mac"
            title="Mac"
          />
        </div>
      </section>

      <section className="section-blog">
        <div className="banner-blog">
          <h2>Kľúčové výhody klientskeho portálu </h2>
          <p>
            Klientsky portál v dnešnej dobe nevyužívajú len veľké firmy, ale aj
            stredné a menšie spoločnosti. Prečo sa oplatí aj vám? Tieto benefity
            nepochybne oceníte.{" "}
          </p>
        </div>
      </section>
      <section id="case-study-info">
        <div class="vyzva">
          <div>
            <div class="iconContainer">
              <img
                className="img"
                src={usporaIcon}
                alt="Úspora času"
                title="Úspora času"
                loading="lazy"
              />
            </div>
          </div>
          <div>
            <h3>
              Úspora času{" "}
              <StaticImage
                className="img"
                src="../../images/new-web/arrow.svg"
                alt="Šípka vpravo"
                placeholder="blurred"
              />
            </h3>
            <p>
              Zákaznícka zóna vám automatizuje mnohé procesy, čím významne šetrí
              čas. Vaši zamestnanci nemusia toľko energie míňať na telefonáty a
              e-maily od zákazníkov či riešenie prácnej administratívy a môžu sa
              radšej venovať dôležitejším úlohám.{" "}
            </p>
          </div>
        </div>
        <div class="vyzva">
          <div>
            <div class="iconContainer">
              <img
                className="img"
                src={znizenieIcon}
                alt="Zníženie nákladov"
                title="Zníženie nákladov"
                loading="lazy"
              />
            </div>
          </div>
          <div>
            <h3>
              Zníženie nákladov{" "}
              <StaticImage
                className="img"
                src="../../images/new-web/arrow.svg"
                alt="Šípka vpravo"
                placeholder="blurred"
              />
            </h3>
            <p>
              Keďže klientska zóna zastreší mnohé každodenné procesy, vaši
              zamestnanci nestrávia toľko času vystavovaním faktúr a objednávok
              či odpovedaním na časté otázky klientov. Ušetríte tak značnú časť
              pravidelných nákladov.{" "}
            </p>
          </div>
        </div>
        <div class="vyzva">
          <div>
            <div class="iconContainer">
              <img
                className="img"
                src={lojalitaIcon}
                alt="Vyššia lojalita zákazníkov"
                title="Vyššia lojalita zákazníkov"
                loading="lazy"
              />
            </div>
          </div>
          <div>
            <h3>
              Vyššia lojalita zákazníkov{" "}
              <StaticImage
                className="img"
                src="../../images/new-web/arrow.svg"
                alt="Šípka vpravo"
                placeholder="blurred"
              />
            </h3>
            <p>
              Klientsky portál vás odlíši od konkurencie a vašim zákazníkom
              poskytne veľmi príjemný používateľský zážitok. Vďaka tomu budú s
              radosťou dlhodobo využívať vaše služby a nakupovať produkty.{" "}
            </p>
          </div>
        </div>
        <div class="vyzva">
          <div>
            <div class="iconContainer">
              <img
                className="img"
                src={fungovanieIcon}
                alt="Nepretržité fungovanie podnikania"
                title="Nepretržité fungovanie podnikania"
                loading="lazy"
              />
            </div>
          </div>
          <div>
            <h3>
              Nepretržité fungovanie podnikania{" "}
              <StaticImage
                className="img"
                src="../../images/new-web/arrow.svg"
                alt="Šípka vpravo"
                placeholder="blurred"
              />
            </h3>
            <p>
              Zákaznícka zóna funguje 24 hodín denne, 7 dní v týždni. Klienti ju
              môžu naplno využívať aj v čase, keď sú všetci vaši zamestnanci
              doma alebo na dovolenke. Podnikanie tak nikdy nespí a môže vám
              generovať tržby počas celého dňa.{" "}
            </p>
          </div>
        </div>

        <div class="vyzva">
          <div>
            <div class="iconContainer">
              <img
                className="img"
                src={rizikoIcon}
                alt="Nižšie riziko chýb a straty dát"
                title="Nižšie riziko chýb a straty dát"
                loading="lazy"
              />
            </div>
          </div>
          <div>
            <h3>
              Nižšie riziko chýb a straty dát{" "}
              <StaticImage
                className="img"
                src="../../images/new-web/arrow.svg"
                alt="Šípka vpravo"
                placeholder="blurred"
              />
            </h3>
            <p>
              Vďaka zákazníckemu portálu sa vám už nestane, že zabudnete na
              nejakú objednávku alebo sa vám stratia dôležité dáta. Všetky
              informácie nájdete na jednom mieste, v prehľadnej podobe.{" "}
            </p>
          </div>
        </div>
      </section>

      <section id="online-business-solutions">
        <h2>Ako prebieha tvorba zákazníckej zóny?</h2>
        <div className="solution-box loaded">
          <img
            className="img solution-img"
            src={uvodnaKonzultaciaImg}
            alt="Úvodná konzultácia"
            title="Úvodná konzultácia"
            loading="lazy"
          />
          <div className="solution-description">
            <h3>1. Úvodná konzultácia</h3>
            <p>
              Poviete nám, s akými problémami sa stretávate vo vašom podnikaní a
              čo by mala vaša budúca zákaznícka zóna riešiť. My vám ponúkneme
              možnosti, čo všetko by mohla obsahovať a aké funkcionality je
              dobré do nej zakomponovať. Vytvoriť pre vás vieme klientsku zónu v
              podobe webovej, mobilnej alebo počítačovej aplikácie.
            </p>
            <br />
            <p className="bold">
              Prvé stretnutie je na nás a trvá 1 – 2 hodiny
            </p>
          </div>
        </div>

        <div className="solution-box loaded">
          <div className="solution-description">
            <h3>2. Návrh riešenia</h3>
            <p>
              Vypracujeme návrh riešenia, ktorý bude obsahovať ceny za
              jednotlivé služby, kalkuláciu počtu hodín práce, čas dodania a
              ďalšie dôležité údaje. Odprezentujeme vám, ako môžu vyzerať
              jednotlivé funkcie a sekcie budúcej klientskej zóny. <br /> <br />
              Pomôže nám, keď pred vypracovaním návrhu riešenia budeme poznať
              vašu predstavu o výzore a funkcionalitách zákazníckej zóny.
              Napríklad, môžete nám poslať screenshot alebo video-záznam z inej
              klientskej zóny, ktorá sa vám páči. Na základe vašich požiadaviek
              a finančných možností vám odporučíme buď grafiku kompletne na
              mieru, alebo dizajn postavený na predpripravenej šablóne, ktorá sa
              ďalej upravuje.
            </p>
            <br />
            <p className="bold">Návrh riešenia nám zaberie 2 – 3 týždne</p>
          </div>
          <img
            className="img solution-img"
            src={navrhRieseniaImg}
            alt="Návrh riešenia"
            title="Návrh riešenia"
            loading="lazy"
          />
        </div>

        <div className="solution-box loaded">
          <img
            className="img solution-img"
            src={programovanieZonyImg}
            alt="Programovanie klientskej zóny"
            title="Programovanie klientskej zóny"
            loading="lazy"
          />
          <div className="solution-description">
            <h3>3. Programovanie klientskej zóny</h3>
            <p>
              Samotné programovanie klientskej zóny je rozdelené na fázy. V
              priebehu tvorby získavame váš feedback a na základe neho
              vykonávame úpravy. V jednotlivých fázach sa vyvíja a prispôsobuje
              aj dizajn.
            </p>
            <br />
            <p className="bold">Realizácia môže trvať 2 - 6 mesiacov</p>
          </div>
        </div>

        <div className="solution-box loaded">
          <div className="solution-description">
            <h3>4. Podpora</h3>
            <p>
              Zákaznícka zóna je tzv. živý projekt. Aby dlhodobo fungovala na
              100 %, vyžaduje pravidelnú údržbu. Po jej dokončení a odovzdaní sa
              postaráme o servis a naša podpora je vám kedykoľvek k dispozícii.
              Ak budete chcieť časom pridať do vašej klientskej zóny nové
              funkcie, vieme vám ich doprogramovať.
            </p>
            <br />
            <p className="bold">Zaškolenie a spustenie môže trvať 2 – 3 dni</p>
          </div>
          <img
            className="img solution-img"
            src={podporaImg}
            alt="Podpora"
            title="Podpora"
            loading="lazy"
          />
        </div>
      </section>

      <References />

      <ZonaClankySlider clanky={pageQuery.data.articles} />

      <section id="zonaFaq">
        <h2>
          Často kladené otázky
          <StaticImage
            className="img"
            src="../../images/new-web/arrow.svg"
            alt="Šípka vpravo"
            placeholder="blurred"
          />
        </h2>

        <ZonaFaqQuestion
          question="Môže mi klientska zóna posielať notifikácie? "
          answer={
            <>
              Áno, túto funkciu odporúčame každej firme, ktorá využíva klientsku
              zónu. Ako vyzerajú notifikácie v praxi? Napríklad, každých 24
              hodín (alebo každý týždeň či mesiac) vám môže na e-mail
              automaticky prísť sumár všetkých objednávok. Takéto reporty sú
              skvelým podkladom pre manažment a dokážu ušetriť veľa času.
            </>
          }
        />

        <ZonaFaqQuestion
          question="Funguje webová klientska zóna aj na mobile? "
          answer={
            <>
              Ak budú vaši zamestnanci či klienti používať webovú klientsku zónu
              v teréne, vieme ju naprogramovať tak, aby bola responzívna. Teda,
              aby sa dala plnohodnotne otvárať a používať aj na mobilných
              zariadeniach.
            </>
          }
        />

        <ZonaFaqQuestion
          question="Koľko peňazí mi ušetrí klientska zóna? "
          answer={
            <>
              Zákaznícka zóna dokáže znížiť náklady firiem aj o 30 %, a to najmä
              tým, že zjednodušuje mnohé procesy a šetrí čas. Vďaka tomu sa vám
              investícia do nej vráti už do 2 rokov.
            </>
          }
        />

        <ZonaFaqQuestion
          question="Ako môže vyzerať vaša budúca zákaznícka zóna? "
          answer={
            <>
              Radi sa s vami o tom porozprávame. Zarezervujte si termín
              bezplatnej telefonickej konzultácie a zistite, čo všetko pre vás
              vieme vytvoriť.
            </>
          }
        />
      </section>
      <ContactUs
        text={<>Máte záujem o vyhotovenie Zákazníckej zóny?</>}
        button="Napíšte nám e-mail"
      />
    </Layout>
  );
};

export default ZakaznickaZona;
